import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

const HeaderContainer = styled("div")`
  padding-top: 3.75em;
  padding-bottom: 3em;
`

const HeaderContent = styled("div")`
  display: flex;
  justify-content: space-between;

  a {
    margin: auto;
  }
`

const AleyaLogo = styled("img")`
  max-width: 500px;
  margin-top: 0.25em;
  width: 100%;
`

const Header = (props) => {
  return (
    <HeaderContainer>
      <HeaderContent>
        <Link to="/">
          <AleyaLogo src={props.logo} />
        </Link>
      </HeaderContent>
    </HeaderContainer>
  )
}

export default Header
