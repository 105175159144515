import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import { Global } from "@emotion/core"
import globalStyles from "styles/global"
import typeStyles from "styles/typography"
import Header from "components/Header"
import "styles/fonts.scss"

const LayoutContainer = styled.div`
  max-width: 100%;
  margin: 0 auto;
  background-color: #000;
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        prismic {
          allHomepages(first: 1) {
            edges {
              node {
                header_image
                footer_image
                footnote
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const layout = data.prismic.allHomepages.edges[0].node

      return (
        <LayoutContainer className="div">
          <Global styles={[globalStyles, typeStyles]} />
          <div className="Layout">
            <Header logo={layout.header_image.url} />
            <main className="Layout__content">{children}</main>
          </div>
        </LayoutContainer>
      )
    }}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
